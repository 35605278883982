export const QUESTION_LIST = [
  { _id: 'one', step: 1, name: 'В какой период увеличивается объем продаж?', answer: '', answers: [
    { _id: 1, name: 'Осенью' },
    { _id: 2, name: 'Зимой' },
    { _id: 3, name: 'Весной' },
    { _id: 4, name: 'Летом' },
  ] },
  { _id: 'two', step: 2, name: 'Сколько разных начинок в прянике «Классик»?', answer: '', answers: [
    { _id: 1, name: '6' },
    { _id: 2, name: '5' },
    { _id: 3, name: '4' },
    { _id: 4, name: '3' },
  ] },
  { _id: 'three', step: 3, name: 'На каких этапах производства происходит контроль качества?', answer: '', answers: [
    { _id: 1, name: 'При изготовлении' },
    { _id: 2, name: 'Подготовке сырья' },
    { _id: 3, name: 'Упаковке' },
    { _id: 4, name: 'На всех' },
  ] },
  { _id: 'four', step: 4, name: 'Какой пряник улучшает состояние организма при физических и умственных нагрузках?', answer: '', answers: [
    { _id: 1, name: 'Аркаша' },
    { _id: 2, name: 'Имбирный батончик' },
    { _id: 3, name: 'Расписной' },
    { _id: 4, name: 'Коврижка' },
  ] },
  { _id: 'five', step: 5, name: 'Какой возраст целевой аудитории преобладает?', answer: '', answers: [
    { _id: 1, name: 'от 18-24' },
    { _id: 2, name: 'от 25-34' },
    { _id: 3, name: 'от 35-44' },
    { _id: 4, name: 'от 46-60' },
  ] },
  { _id: 'six', step: 6, name: 'Что входит в состав Коврижки?', answer: '', answers: [
    { _id: 1, name: 'Изюм и миндаль' },
    { _id: 2, name: 'Сгущенка и грецкий орех' },
    { _id: 3, name: 'Сгущенка и арахис' },
    { _id: 4, name: 'Фруктовый конфитюр' },
  ] },
  { _id: 'seven', step: 7, name: 'Срок хранения пряничной продукции?', answer: '', answers: [
    { _id: 1, name: '100 дней' },
    { _id: 2, name: '1-2 мес' },
    { _id: 3, name: '3-6 мес' },
    { _id: 4, name: '6-9 мес' },
  ] },
  { _id: 'eight', step: 8, name: 'Что самое лучшее в Покровских пряниках?', answer: '', answers: [
    { _id: 1, name: 'Упаковка' },
    { _id: 2, name: 'Вкус' },
    { _id: 3, name: 'Цена' },
    { _id: 4, name: 'Ассортимент' },
  ] },
  { _id: 'nine', step: 9, name: 'В каких пряниках есть белковый крем?', answer: '', answers: [
    { _id: 1, name: 'Расписных' },
    { _id: 2, name: 'Печатных' },
    { _id: 3, name: 'Шоколадных' },
    { _id: 4, name: 'Классических' },
  ] },
  { _id: 'ten', step: 10, name: 'Что символизирует самовар на логотипе Покровского пряника?', answer: '', answers: [
    { _id: 1, name: 'Семью' },
    { _id: 2, name: 'Уют' },
    { _id: 3, name: 'Достаток' },
    { _id: 4, name: 'Гостеприимство' },
  ] },
]