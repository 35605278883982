
import { dynamicsObject } from "@/interfaces";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "TestQuestion",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    selectAnswer(answer: dynamicsObject) {
      this.question.answer = answer._id;
    }
  }
});
