import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f0680e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question flex-column" }
const _hoisted_2 = { class: "question--name leksa" }
const _hoisted_3 = { class: "question--answers grid" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.question.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answers, (answer) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["answer als cursor", { selected: answer._id === _ctx.question.answer }]),
          key: answer._id,
          onClick: ($event: any) => (_ctx.selectAnswer(answer))
        }, _toDisplayString(answer.name), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}