
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { QUESTION_LIST } from '@/store/common/question';
import { useAPI } from "@/use";
import Question from './Question.vue';

export default defineComponent({
  name: 'PartnerTest',
  props: ['empty'],
  data() {
    return {
      questions: QUESTION_LIST,
      question: 1,
      passed: false,
      repeat: false,
    }
  },
  created() {
    this.passed = this.user.test?.passed;
  },
  methods: {
    nextStep() {
      this.question++;
    },
    repeatTest() {
      location.reload();
    },
    async submit() {
      const data = this.questions.map(q => ({ _id: q._id, answer: q.answer }));
      const result = await useAPI().user.updatePartnerTestMethod(data);

      this.$store.commit('updatePartnerTest', result.data.test);
      this.passed = result.data.test.passed;
      this.repeat = !result.data.test.passed;
    }
  },
  computed: {
    ...mapGetters(['user']),
    currentQuestion() {
      return this.questions.find(q => q.step === this.question);
    },
    endQuestion() {
      return this.question >= 10;
    },
    currentTitle() {
      return this.passed ? 'Поздравляем, вы успешно прошли тест!' : this.repeat ? 'К сожалению, вы не прошли тест :(' : 'Пройдите тест, чтобы понять, насколько усвоен материал';
    }
  },
  components: {
    Question
  }
})
