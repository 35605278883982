
import { defineComponent } from "vue-demi";
import PartnerTest from "@/components/partner/test/index.vue";

export default defineComponent({
  name: "PartnerTestView",
  components: {
    PartnerTest,
  },
});
